import React from "react"
import { withPreview } from "gatsby-source-prismic"

import type { AllLayoutsType, UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PublicRoute,
  ROUTES,
  isBrowser,
  LoadingIndicator,
} from "@lesmills/gatsby-theme-common"
import { graphql } from "gatsby"

const TermsOfUse = React.lazy(() => import("../components/TermsOfUse"))

type Props = {|
  pageContext: {
    lang: string,
  },
  data: AllLayoutsType,
|}

const TermsOfUsePage = (props: Props) => {
  const { pageContext, data } = props

  if (!data) return null
  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicLayout = {}, prismicTermsOfUsePage = {} } = data
  const pageContent = prismicTermsOfUsePage.data || {}

  const renderTermOfUsePage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={prismicLayout.data}
      lang={lang}
      user={user}
      isLoading={checkingSession}
    >
      {renderSEO(pageContent, ROUTES(lang).TERMS_OF_USE, lang)}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <TermsOfUse data={pageContent} lang={lang} />
        </React.Suspense>
      )}
    </Layout>
  )

  return <PublicRoute component={renderTermOfUsePage} {...props} lang={lang} />
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutPublicPage
    }
    prismicTermsOfUsePage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicTermsOfUsePageBodyTermsOfUse {
            items {
              term_description {
                raw
                text
              }
              term_navigation_label {
                raw
                text
              }
              term_title {
                raw
                text
              }
            }
            primary {
              content_label {
                raw
                text
              }
              country_code
              country_nav_label {
                raw
                text
              }
              last_update_label {
                raw
                text
              }
              terms_of_use_country_description {
                raw
                text
              }
              terms_of_use_country_title {
                raw
                text
              }
            }
          }
        }
        page_author {
          text
        }
        body1 {
          ... on PrismicTermsOfUsePageBody1DataTable {
            items {
              cell_column_1 {
                raw
                text
              }
              cell_column_2 {
                raw
                text
              }
              cell_column_3 {
                raw
                text
              }
              cell_column_4 {
                raw
                text
              }
            }
            primary {
              header_column_1 {
                raw
                text
              }
              header_column_2 {
                raw
                text
              }
              header_column_3 {
                raw
                text
              }
              header_column_4 {
                raw
                text
              }
              id
            }
          }
        }
        body2 {
          ... on PrismicTermsOfUsePageBody2NoteBoard {
            primary {
              note_id
              note_content {
                raw
                text
              }
            }
          }
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        title {
          text
          raw
        }
      }
    }
  }
`

export default withPreview(TermsOfUsePage)
